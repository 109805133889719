import { InternalMappingData, MappingSourceData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { InternalNodeType } from '@type/shared/enum-mapping.type';

export class TreeNodeUtil {
  public static getStaticArrayParent(
    treeNode: TreeNode<InternalMappingData>
  ): TreeNode<InternalMappingData> | undefined {
    if (treeNode.data.type === InternalNodeType.STATIC_ARRAY) {
      return treeNode;
    } else if (treeNode.parent) {
      return this.getStaticArrayParent(treeNode.parent);
    } else {
      return undefined;
    }
  }

  public static getFieldChildrens(treeNode: TreeNode<InternalMappingData>): TreeNode<InternalMappingData>[] {
    if (treeNode.data.type === InternalNodeType.FIELD) {
      return [treeNode];
    } else {
      return treeNode.children.map(childNode => this.getFieldChildrens(childNode)).flat();
    }
  }

  public static fillMappingSourceData(
    expectedLength: number,
    mappingSourceData: MappingSourceData[]
  ): MappingSourceData[] {
    const filledMappingSourceData: MappingSourceData[] = [];
    for (let i: number = mappingSourceData.length; i <= expectedLength; i++) {
      if (i >= mappingSourceData.length) {
        filledMappingSourceData.push(new MappingSourceData());
      } else {
        filledMappingSourceData.push(mappingSourceData[i]);
      }
    }
    return filledMappingSourceData;
  }
}
