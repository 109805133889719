export enum DataFormatType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
}

export enum ExternalNodeType {
  OBJECT = 'OBJECT',
  ARRAY = 'ARRAY',
  STATIC_ARRAY = 'STATIC_ARRAY',
  ANONYMOUS_OBJECT = 'ANONYMOUS_OBJECT',
  FIELD = 'FIELD',
}

export enum InternalNodeType {
  OBJECT = 'OBJECT',
  ARRAY = 'ARRAY',
  STATIC_ARRAY = 'STATIC_ARRAY',
  FIELD = 'FIELD',
}

export enum PaymentIntervalType {
  FIXED = 'FIXED',
  ANNUALLY = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  ONCE_TO_EXPENDITURE = 'ONCE_TO_EXPENDITURE',
  CUSTOM_OFFER = 'CUSTOM_OFFER',
}

export enum MapperValueType {
  CONST = 'CONST',
  SELECTED_PRODUCT_PROPERTY = 'SELECTED_PRODUCT_PROPERTY',
  ADDITIONAL_PRODUCT_PROPERTY = 'ADDITIONAL_PRODUCT_PROPERTY',
  PRODUCT_DATA_FIELD_VALUE = 'PRODUCT_DATA_FIELD_VALUE',
  GENERAL_DATA_FIELD_VALUE = 'GENERAL_DATA_FIELD_VALUE',
}

export enum MapperOperatorType {
  EQ = 'EQ',
  NEQ = 'NEQ',
}

/* FOR MAPPING UI */
export enum DataFieldGroupType {
  GENERAL = 'GENERAL',
  SHARED = 'SHARED',
  MULTIPLIED = 'MULTIPLIED',
}

export enum DataFieldType {
  TEXT = 'TEXT',
  LONG_TEXT = 'LONG_TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  RADIO_GROUP = 'RADIO_GROUP',
  CHECKBOX = 'CHECKBOX',
  FILES_IMAGES = 'FILES_IMAGES',
  HIDDEN = 'HIDDEN',
  PRICE = 'PRICE',
}

export enum ProductType {
  MAIN = 'MAIN',
  VARIANT = 'VARIANT',
  ADDITIONAL = 'ADDITIONAL',
}

export enum ProductPropertyType {
  NAME = 'NAME',
  QUANTITY = 'QUANTITY',
  DESCRIPTION = 'DESCRIPTION',
  PRICE = 'PRICE',
  VAT = 'VAT',
  PAYMENT_INTERVAL = 'PAYMENT_INTERVAL',
}

export class DefaultType {
  public static readonly DATA_FIELD: DataFieldType = DataFieldType.TEXT;
  public static readonly PRODUCT: ProductType = ProductType.ADDITIONAL;
  public static readonly DATA_FIELD_GROUP: DataFieldGroupType = DataFieldGroupType.GENERAL;
  public static readonly DATA_FORMAT: DataFormatType = DataFormatType.STRING;
  public static readonly NODE_EXPORT: ExternalNodeType = ExternalNodeType.OBJECT;
  public static readonly NODE_DATA: InternalNodeType = InternalNodeType.FIELD;
  public static readonly PAYMENT_INTERVAL: PaymentIntervalType = PaymentIntervalType.FIXED;
  public static readonly VALUE: MapperValueType = MapperValueType.GENERAL_DATA_FIELD_VALUE;
  public static readonly OPERATOR: MapperOperatorType = MapperOperatorType.EQ;
}

export function toExternalType(internalType: InternalNodeType): ExternalNodeType {
  switch (internalType) {
    case InternalNodeType.OBJECT:
      return ExternalNodeType.OBJECT;
    case InternalNodeType.ARRAY:
      return ExternalNodeType.ARRAY;
    case InternalNodeType.STATIC_ARRAY:
      return ExternalNodeType.STATIC_ARRAY;
    case InternalNodeType.FIELD:
      return ExternalNodeType.FIELD;
  }
}
