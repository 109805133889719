import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TreeNodeUtil } from '@app/util/tree-node.util';
import { ExternalMappingData } from '@type/external/external-mapping.type';
import { DefaultData } from '@type/internal/default.data';
import { InternalMappingData, MappingSourceData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { ExternalNodeType, InternalNodeType } from '@type/shared/enum-mapping.type';

@Injectable({
  providedIn: 'root',
})
export class ImportMappingService {
  private _JSON_PATH = '../assets/export-mapping-test01.json';

  constructor(private httpClient: HttpClient) {}

  public convertFromJson(json: string): TreeNode<InternalMappingData> {
    const externalMappingDataRoot = this.deserializeJson(json);
    const treeRoot = this.convertTree(externalMappingDataRoot);
    return treeRoot;
  }

  private deserializeJson(object: Object): ExternalMappingData {
    const json = JSON.stringify(object);
    const exportDatas = <ExternalMappingData>JSON.parse(json);
    return exportDatas;
  }

  private convertTree(exportMapping: ExternalMappingData): TreeNode<InternalMappingData> {
    const rootTreeNode = this.convertNode(exportMapping);
    if (!rootTreeNode.data) {
      rootTreeNode.data = new InternalMappingData({ type: ExternalNodeType.OBJECT });
    }
    rootTreeNode.name = DefaultData.ROOT_NAME;
    rootTreeNode.kfNodeInitiallySelected = true;
    rootTreeNode.data.type = InternalNodeType.OBJECT;
    rootTreeNode.metaData = exportMapping.metaData;
    return rootTreeNode;
  }

  private convertNode(exportData: ExternalMappingData): TreeNode<InternalMappingData> {
    if (exportData.type === ExternalNodeType.STATIC_ARRAY) {
      const mappingSourceLength = exportData.fields!.length;
      const treeNode = this.convertStaticArrayNode(exportData, mappingSourceLength);
      treeNode.name = exportData.name!;
      treeNode.data.selectedMappingSourceIndex = 0;
      treeNode.data.mappingSourceLength = mappingSourceLength;
      return treeNode;
    } else {
      return this.convertNonStaticNode(exportData);
    }
  }

  private convertNonStaticNode(exportData: ExternalMappingData): TreeNode<InternalMappingData> {
    const node = this.convertFieldNode(exportData);
    if (exportData.fields) {
      const treeChildrenNodes = exportData.fields.map(childDataNode => this.convertNode(childDataNode));
      node.addChildren(treeChildrenNodes);
    }
    return node;
  }

  private convertFieldNode(
    exportData: ExternalMappingData,
    mappingSourceLength?: number
  ): TreeNode<InternalMappingData> {
    const nodeData = new InternalMappingData(exportData);
    if (exportData.mappers) {
      nodeData.mappingSources = [{ mappers: exportData.mappers }];
    }

    if (mappingSourceLength) {
      nodeData.selectedMappingSourceIndex = 0;
      nodeData.mappingSourceLength = mappingSourceLength;
      nodeData.mappingSources = TreeNodeUtil.fillMappingSourceData(mappingSourceLength, nodeData.mappingSources ?? []);
    }

    return new TreeNode<InternalMappingData>(exportData.name!, nodeData);
  }

  private convertStaticArrayNode(
    staticArrayMapping: ExternalMappingData,
    mappingSourceLength: number
  ): TreeNode<InternalMappingData> {
    if (staticArrayMapping.fields) {
      return this.convertAnonymousObjectNodes(staticArrayMapping.fields, mappingSourceLength);
    }
    const nodeData = new InternalMappingData({ type: ExternalNodeType.STATIC_ARRAY });
    return new TreeNode<InternalMappingData>(staticArrayMapping.name ?? '', nodeData);
  }

  private convertAnonymousObjectNodes(
    anonymousObjects: ExternalMappingData[],
    mappingSourceLength: number
  ): TreeNode<InternalMappingData> {
    const expected = anonymousObjects[0];
    const invalidStructure = anonymousObjects.find(actual => !this.hasSameProperties(actual, expected));
    if (invalidStructure) {
      //TODO extract to seperate validation
      console.error(invalidStructure);
      throw new Error('Invalid structure');
    }

    if (expected.fields) {
      const childrenNodes: TreeNode<InternalMappingData>[] = [];
      for (let index in expected.fields) {
        const anonymousChildObjects = anonymousObjects.map(ao => {
          return ao.fields![index];
        });
        childrenNodes[index] = this.convertAnonymousObjectNodes(anonymousChildObjects, mappingSourceLength);
      }

      const parentNode = this.convertFieldNode(expected, mappingSourceLength);
      parentNode.addChildren(childrenNodes);
      return parentNode;
    }

    const mappingDatas: MappingSourceData[] = anonymousObjects.map(ao => {
      return {
        mappers: ao.mappers!,
      };
    });

    const fieldNode = this.convertFieldNode(expected, mappingSourceLength);
    fieldNode.data.mappingSources = mappingDatas;
    return fieldNode;
  }

  private hasSameProperties(actual: ExternalMappingData, expected: ExternalMappingData): boolean {
    return (
      actual.type == expected.type &&
      actual.name == expected.name &&
      actual.source == expected.source &&
      actual.format == expected.format &&
      actual.fields?.length == expected.fields?.length &&
      actual.mappers?.length == expected.mappers?.length
    );
  }
}
