import { Component, Input } from '@angular/core';
import { MapperOperatorType } from '@type/shared/enum-mapping.type';
import { Mapper, MapperCondition, createCondition, createMapper } from '@type/shared/mapper.type';

@Component({
  selector: 'app-value-mapper',
  templateUrl: './value-mapper.component.html',
  styleUrls: ['./value-mapper.component.scss'],
})
export class ValueMapperComponent {
  @Input() public mappers?: Mapper[];

  constructor() {}

  public getMapperHeader(mappers: Mapper[], index: number): string {
    const ue = String.fromCharCode(252);
    if (mappers.length === 1) {
      return 'Variable value mapped from';
    }
    return `Variable ${index + 1} of ${mappers.length} mapped from`;
  }

  public getConditionHeader(conditions: MapperCondition[], index: number): string {
    if (conditions.length === 1) {
      return 'when condition';
    }
    return `when ${index + 1} of ${conditions.length} conditions`;
  }

  public remove(array: any[], index: number): void {
    array.splice(index, 1);
  }

  public addMapper(): void {
    this.mappers!.push(createMapper());
  }

  public addCondition(conditions: MapperCondition[]): void {
    conditions.push(createCondition());
  }

  /* INPUT CHOICE GETTERS */

  public get equalsOperator(): MapperOperatorType {
    return MapperOperatorType.EQ;
  }

  public get notEqualsOperator(): MapperOperatorType {
    return MapperOperatorType.NEQ;
  }

  /* TYPECASTING GETTERS */
  public asMapper(mapperUntyped: any): Mapper {
    return mapperUntyped as Mapper;
  }

  public asMappers(mappersUntyped: any[]): Mapper[] {
    return mappersUntyped as Mapper[];
  }

  public asCondition(conditionUntyped: any): MapperCondition {
    return conditionUntyped as MapperCondition;
  }

  public asConditions(conditionsUntyped: any[]): MapperCondition[] {
    return conditionsUntyped as MapperCondition[];
  }
}
