<div *ngIf="mappers">
  <ng-container *ngFor="let mapper of mappers; let mapperIndex = index; let last = last">
    <kf-tile>
      <button
        mat-icon-button
        class="kf-icon-button-small kf-icon-button-neutral remove-button"
        style="cursor: pointer"
        (click)="remove(mappers, mapperIndex)"
      >
        <kf-icon name="ic_schliessen_24"></kf-icon>
      </button>

      <h5>{{ getMapperHeader(mappers, mapperIndex) }}</h5>

      <app-single-value [value]="mapper.value"></app-single-value>

      <ng-container *ngTemplateOutlet="multipleConditionsView; context: { conditionsUntyped: mapper.conditions }">
      </ng-container>
    </kf-tile>

    <div *ngIf="mappers.length > 1 && !last" class="my-3"></div>
  </ng-container>

  <button mat-button style="cursor: pointer" (click)="addMapper()">
    <kf-icon name="ic_pluszeichen_24"></kf-icon>
    <div>Add Variable</div>
  </button>
</div>

<ng-template #multipleConditionsView let-conditionsUntyped="conditionsUntyped">
  <div *ngIf="asConditions(conditionsUntyped) as conditions">
    <ng-container *ngFor="let condition of conditions; let conditionIndex = index; let last = last">
      <kf-tile>
        <button
          mat-icon-button
          class="kf-icon-button-small kf-icon-button-neutral remove-button"
          style="cursor: pointer"
          (click)="remove(conditions, conditionIndex)"
        >
          <kf-icon name="ic_schliessen_24"></kf-icon>
        </button>
        <h6>{{ getConditionHeader(conditions, conditionIndex) }}</h6>
        <ng-container *ngTemplateOutlet="singleConditionView; context: { conditionUntyped: condition }"></ng-container>
      </kf-tile>
      <div *ngIf="conditions.length > 1 && !last" class="my-3"></div>
    </ng-container>
    <button mat-button class="kf-icon-button-small" style="cursor: pointer" (click)="addCondition(conditions)">
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>when...</span>
    </button>
  </div>
</ng-template>

<ng-template #singleConditionView let-conditionUntyped="conditionUntyped">
  <div *ngIf="asCondition(conditionUntyped) as condition">
    <app-single-value [value]="condition.keyValue"></app-single-value>
    <div class="d-flex m-3">
      <mat-radio-group>
        <mat-radio-button
          [(ngModel)]="condition.operator"
          [value]="equalsOperator"
          [checked]="condition.operator === equalsOperator"
        >
          equals
        </mat-radio-button>
        <mat-radio-button
          [(ngModel)]="condition.operator"
          [value]="notEqualsOperator"
          [checked]="condition.operator === notEqualsOperator"
        >
          does not equals
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <app-single-value [value]="condition.matchingValue"></app-single-value>
  </div>
</ng-template>
