<div class="d-flex flex-column" *ngIf="searchValue">
  <mat-form-field class="kf-filter-dropdown-field">
    <mat-label>{{ labelName }}</mat-label>
    <input
      type="text"
      aria-label="labelName"
      matInput
      [matAutocomplete]="auto"
      [formControl]="searchValue.fieldControl"
      (focus)="searchValue.setInputFocus()"
    />
    <kf-icon
      name="ic_chevron_unten_24"
      class="kf-filter-dropdown-field-chevron"
      [ngClass]="{
        'kf-filter-dropdown-field-chevron-open': searchValue.overlayOpen.value,
        'kf-filter-dropdown-field-chevron-close': !searchValue.overlayOpen.value
      }"
      matSuffix
      (click)="searchValue.toggleOverlay(!searchValue.overlayOpen.value, $event)"
    ></kf-icon>

    <mat-autocomplete
      #auto="matAutocomplete"
      (closed)="searchValue.setOverlayClosed()"
      (opened)="searchValue.setOverlayOpen()"
      autoSelectActiveOption="true"
      (optionSelected)="searchValue.setSelection($event.option.value)"
      [displayWith]="searchValue.getOptionLabelFunction()"
    >
      <div *ngIf="searchValue.showAutocompleteOverlay">
        <mat-option
          class="kf-filter-dropdown-field-option"
          *ngFor="let option of searchValue.filteredOptions"
          [value]="option"
        >
          <span [innerHTML]="searchValue.highlightText(searchValue.getOptionLabel(option))"></span>
        </mat-option>
        <mat-option *ngIf="searchValue.filteredWithNoResults" style="pointer-events: none" [value]="undefined">
          <div class="kf-autocomplete-no-results">No result found</div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </mat-form-field>

  <div class="d-flex mb-1 pb-2">
    <mat-slide-toggle
      class="ml-auto"
      [checked]="searchValue.showContentId.getValue()"
      (toggleChange)="searchValue.toggleContentIdDisplay()"
      color="primary"
      labelPosition="before"
      [disabled]="searchValue.disableToggleContentIdDisplay()"
    >
      Show Content-Id
    </mat-slide-toggle>
  </div>
</div>
