import { Component, Input, OnInit } from '@angular/core';
import { TreeNodeUtil } from '@app/util/tree-node.util';
import { InternalMappingData, MappingSourceData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { DataFormatType } from '@type/shared/enum-mapping.type';
import { Mapper } from '@type/shared/mapper.type';
import { MapperViewType } from './mapper-view.enum';

@Component({
  selector: 'app-field-mapper',
  templateUrl: './field-mapper.component.html',
  styleUrls: ['./field-mapper.component.scss'],
})
export class FieldMapperComponent implements OnInit {
  @Input() public treeNode?: TreeNode<InternalMappingData>;
  @Input() public mapperContext?: MapperViewType;
  private _staticArrayParent?: TreeNode<InternalMappingData>;

  constructor() {}

  ngOnInit(): void {
    this._staticArrayParent = TreeNodeUtil.getStaticArrayParent(this.treeNode!);
  }

  public getSelectedMappingNode(): Mapper[] {
    return this.nodeData!.mappingSources![this.nodeData!.selectedMappingSourceIndex ?? 0].mappers;
  }

  public getMappingNode(index: number): Mapper[] {
    return this.nodeData!.mappingSources![index].mappers;
  }

  public getMapperTabLabel(index: number): string {
    return `Index ${index}`;
  }

  public getFormats(): DataFormatType[] {
    return Object.values(DataFormatType);
  }

  public getSelectableIndexes(): number[] {
    const maxSelectableIndex = this.nodeData?.mappingSources?.length ?? 0;
    return [...Array(maxSelectableIndex)].map((_, i) => i++);
  }

  public addMapperTab(): void {
    this._staticArrayParent!.data.mappingSourceLength!++;
    this._staticArrayParent!.walkDown(() => true, {
      preAction: node => node.data.mappingSources?.push(new MappingSourceData()),
    });
  }

  public removeMapperTab(index: number): void {
    this._staticArrayParent!.data.mappingSourceLength!--;
    this._staticArrayParent!.walkDown(() => true, { preAction: node => node.data.mappingSources?.splice(index, 1) });
  }

  public isStaticArrayMapper(): boolean {
    return this.mapperContext === MapperViewType.STATIC_ARRAY_MAPPER;
  }

  public get nodeData(): InternalMappingData | undefined {
    return this.treeNode?.data;
  }
}
