import { Component } from '@angular/core';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';

@Component({
  selector: 'app-node-view',
  templateUrl: './node-view.component.html',
  styleUrls: ['./node-view.component.scss'],
})
export class NodeViewComponent {
  public opened: boolean = true;
  public rootTreeNode?: TreeNode<InternalMappingData>;
}
