import {
  DefaultType,
  MapperOperatorType,
  MapperValueType,
  PaymentIntervalType,
  ProductPropertyType,
} from './enum-mapping.type';

export interface Mapper {
  value: MapperValue;
  conditions: MapperCondition[];
}

export interface MapperValue {
  type: MapperValueType;
  value?: string;
  property?: ProductPropertyType;
  dataFieldContentId?: string;
  contentId?: string;
  paymentInterval?: PaymentIntervalType;
}

export interface MapperCondition {
  keyValue: MapperValue;
  operator: MapperOperatorType;
  matchingValue: MapperValue;
}

export function createMapper(): Mapper {
  return {
    value: createValue(),
    conditions: [],
  };
}

export function createCondition(): MapperCondition {
  return {
    keyValue: createValue(),
    operator: DefaultType.OPERATOR,
    matchingValue: createValue(),
  };
}

function createValue(): MapperValue {
  return {
    type: DefaultType.VALUE,
  };
}
