import {
  CompositionDTO,
  DataFieldDTO,
  DataFieldGroupDTO,
  DataFieldGroupTypeDTO,
  DataFieldTypeDTO,
  InstanceDTO,
  ProductDTO,
  ProductRefDTO,
  ProductTypeDTO,
} from '@type/external/banking-guide.type';
import {
  DataFieldGroupType,
  DataFieldType,
  DefaultType,
  ExternalNodeType,
  InternalNodeType,
  ProductType,
} from '../shared/enum-mapping.type';

export class InstanceData {
  public readonly id: string;
  public readonly name: string;
  public readonly status: string;
  public readonly contentStream: string;
  public readonly contentVersion: string;
  public readonly live: boolean;

  constructor(instance: InstanceDTO) {
    this.id = instance.id;
    this.name = instance.name;
    this.status = instance.status;
    this.contentStream = instance.contentStream;
    this.contentVersion = instance.contentVersion;
    this.live = instance.live;
  }
}

export class AbstractContentData {
  constructor(protected _id?: string, protected _contentId?: string, protected _displayName?: string) {}

  public get id(): string {
    return this._id!;
  }

  public get contentId(): string {
    return this._contentId!;
  }

  public get displayName(): string {
    return this._displayName!;
  }
}

export class DataFieldGroupData extends AbstractContentData {
  public readonly type: DataFieldGroupType;
  constructor(dataFieldGroup?: DataFieldGroupDTO) {
    super(dataFieldGroup?.id, dataFieldGroup?.contentId, dataFieldGroup?.displayName);
    this.type = toDataFieldGroupType(dataFieldGroup?.type);
  }
}

export class DataFieldData extends AbstractContentData {
  public readonly type?: DataFieldType;

  constructor(dataField?: DataFieldDTO) {
    super(dataField?.id, dataField?.contentId, dataField?.displayName);
    this.type = toDataFieldType(dataField?.type);
  }
}

export class ProductData extends AbstractContentData {
  public type?: ProductType;
  public compositionId?: string;
  public compositionContentId?: string;
  public compositionDisplayName?: string;

  constructor(productRef?: ProductRefDTO, composition?: CompositionDTO) {
    super(productRef?.productId);
    this.type = toProductType(productRef?.type);
    this.compositionId = composition?.id;
    this.compositionContentId = composition?.contentId;
    this.compositionDisplayName = composition?.displayName;
  }

  public addProductData(product: ProductDTO | undefined): void {
    if (product && product.id == this._id) {
      this._contentId = product.id;
      this._displayName = product.displayName;
    } else {
      throw new Error(`invalid product state for product with id: ${this._id}`);
    }
  }
}

export function toNodeDataType(exportDataType?: ExternalNodeType): InternalNodeType {
  switch (exportDataType) {
    case ExternalNodeType.OBJECT:
      return InternalNodeType.OBJECT;
    case ExternalNodeType.ARRAY:
      return InternalNodeType.ARRAY;
    case ExternalNodeType.STATIC_ARRAY:
    case ExternalNodeType.ANONYMOUS_OBJECT:
      return InternalNodeType.STATIC_ARRAY;
    case ExternalNodeType.FIELD:
      return InternalNodeType.FIELD;
    default:
      return DefaultType.NODE_DATA;
  }
}

function toDataFieldType(dto?: DataFieldTypeDTO): DataFieldType {
  if (dto === undefined) {
    return DefaultType.DATA_FIELD;
  }
  switch (dto) {
    case DataFieldTypeDTO.TEXT:
      return DataFieldType.TEXT;
    case DataFieldTypeDTO.LONG_TEXT:
      return DataFieldType.LONG_TEXT;
    case DataFieldTypeDTO.NUMBER:
      return DataFieldType.NUMBER;
    case DataFieldTypeDTO.DATE:
      return DataFieldType.DATE;
    case DataFieldTypeDTO.DROPDOWN:
      return DataFieldType.DROPDOWN;
    case DataFieldTypeDTO.RADIO_GROUP:
      return DataFieldType.RADIO_GROUP;
    case DataFieldTypeDTO.CHECKBOX:
      return DataFieldType.CHECKBOX;
    case DataFieldTypeDTO.RADIO_GROUP:
      return DataFieldType.RADIO_GROUP;
    case DataFieldTypeDTO.FILES_IMAGES:
      return DataFieldType.FILES_IMAGES;
    case DataFieldTypeDTO.HIDDEN:
      return DataFieldType.HIDDEN;
    case DataFieldTypeDTO.PRICE:
      return DataFieldType.PRICE;
  }
}

function toProductType(dto?: ProductTypeDTO): ProductType {
  if (dto === undefined) {
    return DefaultType.PRODUCT;
  }
  switch (dto) {
    case ProductTypeDTO.MAIN:
      return ProductType.MAIN;
    case ProductTypeDTO.VARIANT:
      return ProductType.VARIANT;
    case ProductTypeDTO.ADDITIONAL:
      return ProductType.ADDITIONAL;
  }
}

function toDataFieldGroupType(dto?: DataFieldGroupTypeDTO): DataFieldGroupType {
  if (dto === undefined) {
    return DefaultType.DATA_FIELD_GROUP;
  }
  switch (dto) {
    case DataFieldGroupTypeDTO.GENERAL:
      return DataFieldGroupType.GENERAL;
    case DataFieldGroupTypeDTO.MULTIPLIED:
      return DataFieldGroupType.MULTIPLIED;
    case DataFieldGroupTypeDTO.SHARED:
      return DataFieldGroupType.SHARED;
  }
}
