<div class="header-container">
  <h1 class="ml-3 mb-0" color="primary">Data Export Mapper</h1>

  <div class="input-container">
    <mat-form-field class="kf-form-field-compact instance-dropdown">
      <mat-label>Instance</mat-label>
      <mat-select
        kfSelectMdc
        (valueChange)="selectInstance($event)"
        [disabled]="!instances"
        [(ngModel)]="selectedInstance"
      >
        <mat-option *ngFor="let instance of instances" [value]="instance">{{ instance.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button class="mr-2" styles="width=10em" [matMenuTriggerFor]="menu" mat-icon-button>
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu>
    <div style="min-width: 10em">
      <button mat-menu-item class="kfcolor-grey-600" (click)="openDialog(importDialogView)">
        <kf-icon name="ic_hochladen_24"></kf-icon>
        Import
      </button>

      <button mat-menu-item class="kfcolor-grey-600" (click)="onExport()">
        <kf-icon name="ic_herunterladen_24"></kf-icon>
        Export
      </button>
      <button mat-menu-item class="kfcolor-grey-600" (click)="onRefresh()" [disabled]="!canRefresh()">
        <kf-icon name="ic_aktualisieren_24"></kf-icon>
        Refresh Data
      </button>

      <mat-divider></mat-divider>
      <button mat-menu-item class="kfcolor-grey-600" (click)="onLogOut()">
        <kf-icon name="ic_abmelden_24"></kf-icon>
        Logout
      </button>
    </div>
  </mat-menu>
</div>

<ng-template #importDialogView>
  <div class="kf-popup-container">
    <div class="kf-popup-header mat-h1">Import Data Export Mapping</div>
    <div class="mat-body-1 kf-mt-2">
      <label>All current changes will be discarded</label>
      <div class="d-flex mb-1 pb-2">
        <mat-slide-toggle [(ngModel)]="importIgnoreContext" color="primary" labelPosition="before">
          Ignore Metadata Context
        </mat-slide-toggle>
      </div>
    </div>
    <div class="kf-popup-buttons">
      <button mat-stroked-button color="primary" (click)="onImportCancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="fileInput.click()">
        <input
          #fileInput
          type="file"
          class="file-upload"
          (change)="onImportConfirm($event)"
          accept="application/json"
          style="display: none"
        />
        Confirm
      </button>
    </div>
  </div>
</ng-template>

<ng-template #importSuccessView>
  <div class="kf-ntofication-content" kfNotification kfNotificationStyle="Success">
    <kf-icon name="ic_haken_24"></kf-icon>
    <span class="kf-notification-text">Import successful</span>
  </div>
</ng-template>

<ng-template #importErrorView>
  <div class="kf-ntofication-content" kfNotification kfNotificationStyle="Error">
    <kf-icon name="ic_warnschild_24"></kf-icon>
    <span class="kf-notification-text">{{ importErrorMessage }}</span>
  </div>
</ng-template>
