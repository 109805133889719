import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractAuthenticationService, AuthenticationState } from '@service/authentication.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent implements OnInit, OnDestroy {
  @ViewChild('wrongPasswordNotification') public wrongPasswordTemplate?: TemplateRef<Element>;
  @Input() public username?: string;
  @Input() public password?: string;
  @Input() public hidePassword: boolean;
  @Input() public loginError: boolean;

  private _destroy: Subject<boolean>;

  constructor(
    private readonly snackBar: MatSnackBar,
    @Inject('AuthenticationService') private _authenticationService: AbstractAuthenticationService
  ) {
    this.hidePassword = true;
    this.loginError = false;
    this._destroy = new Subject();
  }

  ngOnInit(): void {
    this._authenticationService.authorizationState
      .pipe(takeUntil(this._destroy))
      .subscribe(state => this._handleAuthenticationState(state));
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public canLogin(): boolean {
    return (this.username?.length ?? -1) > 2 && (this.password?.length ?? -1) > 2;
  }

  public login(): void {
    this._authenticationService.login(this.username!, this.password!);
  }

  private _handleAuthenticationState(state: AuthenticationState): void {
    if (state === AuthenticationState.LOGIN_ERROR && this.wrongPasswordTemplate) {
      this.snackBar.openFromTemplate(this.wrongPasswordTemplate);
    }
  }
}
