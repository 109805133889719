import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstanceDTO } from '@type/external/banking-guide.type';
import { environment } from 'environment/environment';
import { Observable } from 'rxjs';
import { AbstractBaseRepository } from './abstract-base.repository';

@Injectable({
  providedIn: 'root',
})
export class GuidedServiceHubRepository extends AbstractBaseRepository {
  private static readonly serviceNameWithSuffix = `bgzv-backend-guided-service-hub/api/v1`;
  constructor(protected http: HttpClient) {
    super(environment.gshUrl, GuidedServiceHubRepository.serviceNameWithSuffix);
  }

  public getInstances(): Observable<InstanceDTO[]> {
    return this.http.get<InstanceDTO[]>(`${this.url}/instances`, {});
  }
}
