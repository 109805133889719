<kf-tile [interactive]="false" class="grid-container" *ngIf="nodeData">
  <mat-form-field class="kf-form-field">
    <mat-label>Data type</mat-label>
    <mat-select kfSelectMdc [(ngModel)]="nodeData.format">
      <mat-option *ngFor="let format of getFormats()" [value]="format">{{ format }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="isStaticArrayMapper(); then staticArrayMapperView; else singleMapperView"></div>

  <ng-template #staticArrayMapperView>
    <mat-tab-group animationDuration="0ms" [(selectedIndex)]="nodeData.selectedMappingSourceIndex">
      <mat-tab *ngFor="let index of getSelectableIndexes()">
        <ng-template mat-tab-label>
          <span>{{ getMapperTabLabel(index) }}</span>
          <button
            mat-icon-button
            matSuffix
            disableRipple
            class="kf-icon-button-small ml-4"
            style="pointer-events: auto"
            (click)="removeMapperTab(index); $event.stopPropagation()"
          >
            <kf-icon name="ic_schliessen_16"></kf-icon>
            <!-- <mat-icon style="font-size: 1rem; align-self: center">close</mat-icon> -->
          </button>
        </ng-template>
        <body *ngIf="getMappingNode(index) as mapper">
          <app-value-mapper [mappers]="mapper"> </app-value-mapper>
        </body>
      </mat-tab>

      <mat-tab disabled>
        <ng-template mat-tab-label>
          <button
            disableRipple
            mat-icon-button
            class="kf-icon-button-small ml-4"
            style="pointer-events: auto"
            (click)="addMapperTab(); $event.stopPropagation()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <ng-template #singleMapperView>
    <body *ngIf="getSelectedMappingNode() as selected">
      <app-value-mapper [mappers]="selected"> </app-value-mapper></body
  ></ng-template>
</kf-tile>
