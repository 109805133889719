import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InstanceContextService } from '@service/instance-context.service';
import { AbstractContentData, DataFieldData, ProductData } from '@type/internal/internal-form.type';
import { MapperValueType, PaymentIntervalType, ProductPropertyType } from '@type/shared/enum-mapping.type';
import { MapperValue } from '@type/shared/mapper.type';
import { Subject, takeUntil } from 'rxjs';
import { SearchValueService } from '../search-value/search-value.service';

@Component({
  selector: 'app-single-value',
  templateUrl: './single-value.component.html',
  styleUrls: ['./single-value.component.scss'],
})
export class SingleValueComponent implements OnInit, OnDestroy {
  @Input() public value?: MapperValue;
  public readonly valueType: typeof MapperValueType;
  public readonly productPropertyType: typeof ProductPropertyType;
  public readonly paymentIntervalType: typeof PaymentIntervalType;

  public readonly generalDataFieldSearchValue: SearchValueService<DataFieldData> = new SearchValueService();
  public readonly productDataFieldSearchValue: SearchValueService<DataFieldData> = new SearchValueService();
  public readonly additionalProductsSearchValue: SearchValueService<ProductData> = new SearchValueService();
  private _destroy: Subject<boolean> = new Subject();

  constructor(private _instanceContextService: InstanceContextService) {
    this.valueType = MapperValueType;
    this.productPropertyType = ProductPropertyType;
    this.paymentIntervalType = PaymentIntervalType;
  }

  ngOnInit(): void {
    this._instanceContextService.generalDataFieldsSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(generalDF => (this.generalDataFieldSearchValue.options = generalDF));
    this._instanceContextService.productDataFieldSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(productDF => (this.productDataFieldSearchValue.options = productDF));
    this._instanceContextService.additionalProductsSubject
      .pipe(takeUntil(this._destroy))
      .subscribe(additionalProducts => (this.additionalProductsSearchValue.options = additionalProducts));

    if (this.value) {
      switch (this.value.type) {
        case MapperValueType.GENERAL_DATA_FIELD_VALUE:
          this.generalDataFieldSearchValue.setInitialSelection(this.value.contentId);
          break;
        case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
          this.productDataFieldSearchValue.setInitialSelection(this.value.contentId);
          break;
        case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
          this.additionalProductsSearchValue.setInitialSelection(this.value.contentId);
          break;
      }

      this.generalDataFieldSearchValue.fieldControl.valueChanges.subscribe(value =>
        this._setValue(MapperValueType.GENERAL_DATA_FIELD_VALUE, value)
      );
      this.productDataFieldSearchValue.fieldControl.valueChanges.subscribe(value =>
        this._setValue(MapperValueType.PRODUCT_DATA_FIELD_VALUE, value)
      );
      this.additionalProductsSearchValue.fieldControl.valueChanges.subscribe(value =>
        this._setValue(MapperValueType.ADDITIONAL_PRODUCT_PROPERTY, value)
      );
    }
  }

  private _setValue(type: MapperValueType, value: string | AbstractContentData): void {
    this.value!.type = type;
    const valueContentId = typeof value === 'string' ? value : value.contentId;
    switch (type) {
      case MapperValueType.GENERAL_DATA_FIELD_VALUE:
      case MapperValueType.PRODUCT_DATA_FIELD_VALUE:
        this.value!.dataFieldContentId = valueContentId;
        break;
      case MapperValueType.ADDITIONAL_PRODUCT_PROPERTY:
        this.value!.contentId = valueContentId;
        break;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  public showPaymentInterval(): boolean {
    const propertyType = this.value?.property;
    switch (propertyType) {
      case ProductPropertyType.PAYMENT_INTERVAL:
      case ProductPropertyType.PRICE:
      case ProductPropertyType.VAT:
        return true;
      case ProductPropertyType.DESCRIPTION:
      case ProductPropertyType.NAME:
      case ProductPropertyType.QUANTITY:
      default:
        return false;
    }
  }

  public asTypedValueData(untyped: any): MapperValue {
    return {
      type: untyped.type,
      value: untyped.value,
      property: untyped.property,
      dataFieldContentId: untyped.dataFieldContentId,
      contentId: untyped.contentId,
      paymentInterval: untyped.paymentInterval,
    };
  }

  public get valueTypeChoices(): MapperValueType[] {
    return Object.values(MapperValueType);
  }

  public get productPropertyChoices(): ProductPropertyType[] {
    return Object.values(ProductPropertyType);
  }

  public get paymentIntervalTypeChoices(): PaymentIntervalType[] {
    return Object.values(PaymentIntervalType);
  }
}
