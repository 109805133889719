import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthorizationInterceptor } from '@app/interceptor/authorization.interceptor';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { KFThemeCommonModule } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { KfCompactButtonModule } from '@de.fiduciagad.kundenportal/kf-theme/compact-button';
import { KFIconModule } from '@de.fiduciagad.kundenportal/kf-theme/icon';
import { KFInputErrorModule } from '@de.fiduciagad.kundenportal/kf-theme/input-error';
import { KfThemeNotificationModule } from '@de.fiduciagad.kundenportal/kf-theme/notification';
import { KFSelectModule } from '@de.fiduciagad.kundenportal/kf-theme/select';
import { KfThemeSideNavigationModule } from '@de.fiduciagad.kundenportal/kf-theme/side-navigation';
import { KfTileModule } from '@de.fiduciagad.kundenportal/kf-theme/tile';
import { KfTopbarModule } from '@de.fiduciagad.kundenportal/kf-theme/topbar';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { environment } from '@environment/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { FieldMapperComponent } from './components/node-view/node-detail/field-mapper/field-mapper.component';
import { SearchValueComponent } from './components/node-view/node-detail/field-mapper/search-value/search-value.component';
import { SingleValueComponent } from './components/node-view/node-detail/field-mapper/single-value/single-value.component';
import { ValueMapperComponent } from './components/node-view/node-detail/field-mapper/value-mapper/value-mapper.component';
import { NodeDetailComponent } from './components/node-view/node-detail/node-detail.component';
import { ObjectNodeComponent } from './components/node-view/node-detail/object-node/object-node.component';
import { NodeHeaderComponent } from './components/node-view/node-header/node-header.component';
import { NodeNavigationComponent } from './components/node-view/node-navigation/node-navigation.component';
import { NodeViewComponent } from './components/node-view/node-view.component';
import { ServiceConstant } from './constants.data';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    ScrollingModule,

    KFThemeCommonModule,
    KfTileModule,
    KFIconModule,
    KFInputErrorModule,
    KFSelectModule,
    KfThemeNotificationModule,
    KfTopbarModule,
    KfThemeSideNavigationModule,
    KfCompactButtonModule,

    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatToolbarModule,
    MatDividerModule,
    MatTreeModule,
    MatTabsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
  declarations: [
    AppComponent,
    NodeHeaderComponent,
    NodeDetailComponent,
    NodeNavigationComponent,
    ObjectNodeComponent,
    FieldMapperComponent,
    ValueMapperComponent,
    SingleValueComponent,
    LoginViewComponent,
    NodeViewComponent,
    SearchValueComponent,
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: ServiceConstant.authenticationService,
      useClass: environment.service!.authentication!,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
