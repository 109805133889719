import { Inject, Injectable } from '@angular/core';
import { ServiceConstant } from '@app/constants.data';
import { AbstractAuthenticationService } from '@service/authentication.service';
import { ExportMappingService } from '@service/converter/export-mapping.service';
import { ImportMappingService } from '@service/converter/import-mapping.service';
import { InstanceContextService } from '@service/instance-context.service';
import { TreeContextService } from '@service/tree-context.service';
import { MetaData } from '@type/external/external-mapping.type';
import { InstanceData } from '@type/internal/internal-form.type';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private static readonly JSON_TYPE: string = 'application/json';

  constructor(
    private _importMappingService: ImportMappingService,
    private _exportMappingService: ExportMappingService,
    private _instanceContextService: InstanceContextService,
    @Inject(ServiceConstant.authenticationService) private _authenticationService: AbstractAuthenticationService,
    private _treeContextService: TreeContextService
  ) {}

  public async importFile(file: File, ignoreContext: boolean): Promise<ImportResult> {
    const fileReader = new FileReader();
    const self = this;
    fileReader.readAsText(file, 'UTF-8');
    return new Promise((resolve, reject) => {
      fileReader.onload = function () {
        const json: string = JSON.parse(fileReader.result as string);
        const result = self.import(json, ignoreContext);
        resolve(result);
      };
      fileReader.onerror = error => {
        console.error(error);
        reject(new ImportResult(ImportResultType.ERROR_INVALID_MAPPING));
      };
    });
  }

  private import(json: string, ignoreContext: boolean): ImportResult {
    try {
      const root: TreeNode<InternalMappingData> = this._importMappingService.convertFromJson(json);
      const metaData = root.metaData;
      if (!ignoreContext && metaData) {
        if (this._authenticationService.tenantId !== root.metaData!.tenantId) {
          return new ImportResult(ImportResultType.ERROR_TENANT_ID, metaData);
        }

        const instances: InstanceData[] = this._instanceContextService.instancesSubject.getValue();
        const instance: InstanceData | undefined = instances.find(
          instance => instance.id === root.metaData!.instanceId
        );
        if (instance === undefined) {
          return new ImportResult(ImportResultType.ERROR_INSTANCE_ID, metaData);
        }

        this._instanceContextService.setNextSelectedInstance(instance);
      }

      this._treeContextService.setRoot(root);
      return new ImportResult(ImportResultType.SUCCESS, metaData);
    } catch (error) {
      console.error(error);
      return new ImportResult(ImportResultType.ERROR_INVALID_MAPPING);
    }
  }

  public async exportAsFile(): Promise<void> {
    const metaData = this._createMetaData();
    const blob = this._exportAsBlob(metaData);
    var file = new File([blob], `exportMapping_${metaData.instanceName}_${metaData.exportDateTime}.json`, {
      type: FileService.JSON_TYPE,
    });
    FileSaver.saveAs(file);
  }

  private _exportAsBlob(metaData: MetaData): Blob {
    const root = this._treeContextService.getRoot();
    const data = this._exportMappingService.convertFromTree(root, metaData);
    const jsonData = JSON.stringify(data);
    const blob = new Blob([jsonData], { type: FileService.JSON_TYPE });
    return blob;
  }

  private _createMetaData(): MetaData {
    const currentDateTime = new Date();
    return {
      tenantId: this._authenticationService.tenantId!,
      instanceId: this._authenticationService.instanceId!,
      instanceName: this._instanceContextService.getSelectedInstance()!.name,
      exportDateTime: currentDateTime.toISOString(),
    };
  }
}

export enum ImportResultType {
  ERROR_TENANT_ID,
  ERROR_INSTANCE_ID,
  ERROR_INVALID_MAPPING,
  SUCCESS,
}

export class ImportResult {
  constructor(public readonly type: ImportResultType, public readonly metaData?: MetaData) {}
}
