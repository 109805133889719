import { NavigationNode } from '@de.fiduciagad.kundenportal/kf-theme/side-navigation';
import { MetaData } from '@type/external/external-mapping.type';
import { v4 as uuid } from 'uuid';
import { Validatable } from './validatable.type';

export class TreeNode<T extends Validatable<T>> implements NavigationNode {
  [x: number]: unknown;
  [x: string]: unknown;
  public kfNodeChildren?: NavigationNode[] | undefined;
  public kfNodeUnselectable: boolean = false;
  public kfNodeInitiallySelected?: boolean | undefined;
  public name: string;

  private _id: string;
  private _children: TreeNode<T>[] = [];
  private _data: T;
  private _parent?: TreeNode<T>;
  private _metaData?: MetaData;
  private _invalidData?: boolean | undefined;

  constructor(name: string, data: T) {
    this._id = uuid();
    this._data = data;
    this.name = name;
    this.kfNodeChildren = this._children;
  }

  public addChild(child: TreeNode<T>) {
    this._children.push(child);
    child._parent = this;
    this.kfNodeChildren = this._children;
  }

  public addChildren(children: TreeNode<T>[]) {
    this._children = this._children.concat(children);
    children.forEach(child => {
      child._parent = this;
    });
    this.kfNodeChildren = this._children;
  }

  public containsInvalidData(): boolean {
    return this._walkDown(
      node => {
        return node._invalidData ?? false;
      },
      { postAction: node => (node.invalidData = true) }
    );
  }

  public walkUp(
    predicate: (treeNode: TreeNode<T>) => boolean,
    actions?: { preAction?: (treeNode: TreeNode<T>) => void; postAction?: (treeNode: TreeNode<T>) => void }
  ): boolean {
    return this._walkUp(predicate, actions);
  }

  public walkDown(
    predicate?: (treeNode: TreeNode<T>) => boolean,
    actions?: { preAction?: (treeNode: TreeNode<T>) => void; postAction?: (treeNode: TreeNode<T>) => void }
  ): boolean {
    return this._walkDown(predicate, actions);
  }

  public hasChildren(): boolean {
    return this._children?.length != 0;
  }

  public isRoot(): boolean {
    return !this._parent;
  }

  public set metaData(metaData: MetaData | undefined) {
    this._metaData = metaData;
  }

  public get metaData(): MetaData | undefined {
    return this._metaData;
  }

  public get invalidData(): boolean | undefined {
    return this._invalidData;
  }

  public set invalidData(value: boolean | undefined) {
    this._invalidData = value;
  }

  public get children(): TreeNode<T>[] {
    return this._children;
  }

  public set data(data: T) {
    this._data = data;
  }

  public get data(): T {
    return this._data;
  }

  public get id(): string {
    return this._id;
  }

  public get parent(): TreeNode<T> | undefined {
    return this._parent;
  }

  private _walkUp(
    predicate: (treeNode: TreeNode<T>) => boolean,
    actions?: { preAction?: (treeNode: TreeNode<T>) => void; postAction?: (treeNode: TreeNode<T>) => void }
  ): boolean {
    const nodeResult = predicate ? predicate(this) : true;
    if (nodeResult && actions?.preAction) {
      actions.preAction(this);
    }
    const resultParent = this._parent ? this._parent._walkUp(predicate, actions) : false;
    const result = nodeResult || resultParent;
    if (result && actions?.postAction) {
      actions.postAction(this);
    }
    return result;
  }

  public _walkDown(
    predicate?: (treeNode: TreeNode<T>) => boolean,
    actions?: { preAction?: (treeNode: TreeNode<T>) => void; postAction?: (treeNode: TreeNode<T>) => void }
  ): boolean {
    const nodeResult = predicate ? predicate(this) : true;
    if (nodeResult && actions?.preAction) {
      actions.preAction(this);
    }
    const resultChildren = this._children
      ? this.children.map(c => c._walkDown(predicate, actions)).reduce((l, r) => l || r, false)
      : false;
    const result = nodeResult || resultChildren;
    if (result && actions?.postAction) {
      actions.postAction(this);
    }
    return result;
  }
}
