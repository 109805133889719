import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AbstractAuthenticationService } from '@service/authentication.service';

import { Inject, Injectable } from '@angular/core';
import { ServiceConstant } from '@app/constants.data';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ServiceConstant.authenticationService) private _authenticationService: AbstractAuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authorizationToken = this._authenticationService.authorizationToken;
    const instanceId = this._authenticationService.instanceId;
    const authRequest = authorizationToken
      ? this._addAuthorizationTokenHeader(request, authorizationToken, instanceId)
      : request;
    return next.handle(authRequest);
  }

  private _addAuthorizationTokenHeader(request: HttpRequest<any>, authorizationToken: string, instanceId?: string) {
    if (instanceId === undefined) {
      return request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + authorizationToken),
      });
    } else {
      return request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${authorizationToken}`)
          .set('Instance-Id', `${instanceId}`),
      });
    }
  }
}
