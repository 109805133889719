import { environment } from '@environment/environment';

export abstract class AbstractBaseRepository {
  protected readonly portalServiceUrl: string | undefined;
  protected readonly portalUrlBase: string | undefined;
  protected readonly url: string | undefined;

  constructor(serviceUrl: string | undefined, serviceNameWithSuffix: string) {
    if (environment.atruvia && environment.atruvia.bwsServiceUrl && !environment.local) {
      if (window.location.pathname?.includes('/bws')) {
        this.url = `${environment.atruvia.okpServiceUrl}/${serviceNameWithSuffix}`;
        this.portalServiceUrl = environment.atruvia.bwsServiceUrl!;
      } else {
        this.url = `${environment.atruvia.okpServiceUrl}/${serviceNameWithSuffix}`;
        this.portalServiceUrl = environment.atruvia.okpServiceUrl!;
      }
      this.portalUrlBase = `/${this.portalServiceUrl}/${environment.atruvia.proxyGatewayPath}`;
    } else {
      this.url = serviceUrl;
      this.portalServiceUrl = undefined;
      this.portalUrlBase = undefined;
    }
  }
}
