import { environment } from '@environment/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';

export interface AbstractAuthenticationService {
  login(email: string, password: string): Promise<any>;
  logout(): void;
  isAuthenticatedWithInstanceContext(): boolean;
  get authorizationToken(): string | undefined;
  get instanceId(): string | undefined;
  set instanceId(instanceId: string | undefined);
  get authorizationState(): BehaviorSubject<AuthenticationState>;
  get tenantId(): string | undefined;
}

export class AuthenticationAtruviaService implements AbstractAuthenticationService {
  private readonly _authorizationState = new BehaviorSubject<AuthenticationState>(AuthenticationState.LOGGED_IN);
  private _instanceId?: string;
  private _tenantId?: string;
  login(email: string, password: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  logout(): void {
    throw new Error('Method not implemented.');
  }
  isAuthenticatedWithInstanceContext(): boolean {
    return (this.authorizationToken ? true : false) && (this.instanceId ? true : false);
  }
  get authorizationToken(): string | undefined {
    throw new Error('Method not implemented.');
  }
  get instanceId(): string | undefined {
    return this._instanceId;
  }
  set instanceId(instanceId: string | undefined) {
    this._instanceId = instanceId;
  }
  get authorizationState(): BehaviorSubject<AuthenticationState> {
    return this._authorizationState;
  }
  get tenantId(): string | undefined {
    return this.tenantId;
  }
}
export class AuthenticationAwsService implements AbstractAuthenticationService {
  private static readonly ATTRIBUTE_KEY_TENANT_ID = 'custom:rzbk';

  private readonly _authorizationState = new BehaviorSubject<AuthenticationState>(AuthenticationState.NOT_LOGGED_IN);
  private _instanceId?: string | undefined;
  private _tenantId?: string;
  private _userPool: CognitoUserPool;
  private _userObject?: CognitoUser;
  private _userSession?: CognitoUserSession;

  constructor() {
    const poolData = {
      UserPoolId: environment.aws!.cognitoUserPoolId!,
      ClientId: environment.aws!.cognitoClientId!,
    };
    this._userPool = new CognitoUserPool(poolData);
  }

  public login(email: string, password: string): Promise<any> {
    const self = this;

    const authenticationData = {
      Username: email.toLowerCase(),
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email.toLowerCase(),
      Pool: this._userPool,
    };
    this._userObject = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      self._userObject!.authenticateUser(authenticationDetails, {
        onSuccess: function (session: CognitoUserSession) {
          self._userSession = session;
          self._userObject!.setSignInUserSession(session);
          self._authorizationState.next(AuthenticationState.LOGGED_IN);
          self._tenantId = session.getIdToken().decodePayload()[AuthenticationAwsService.ATTRIBUTE_KEY_TENANT_ID];
          resolve(session);
        },
        onFailure: function (err) {
          self._authorizationState.next(AuthenticationState.LOGIN_ERROR);
          reject(err);
        },
      });
    });
  }

  public logout(): void {
    this.instanceId = undefined;
    this._authorizationState.next(AuthenticationState.NOT_LOGGED_IN);
    if (this._userObject) {
      this._userObject?.signOut();
    }
  }

  public isAuthenticatedWithInstanceContext(): boolean {
    return (this.authorizationToken ? true : false) && (this.instanceId ? true : false);
  }

  public get authorizationToken(): string | undefined {
    return this._userSession?.getIdToken().getJwtToken();
  }

  public get instanceId(): string | undefined {
    return this._instanceId;
  }
  public set instanceId(value: string | undefined) {
    this._instanceId = value;
  }

  public get authorizationState(): BehaviorSubject<AuthenticationState> {
    return this._authorizationState;
  }

  public get tenantId(): string | undefined {
    return this._tenantId;
  }
}

export enum AuthenticationState {
  NOT_LOGGED_IN,
  LOGIN_ERROR,
  LOGGED_IN,
}
