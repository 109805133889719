import { ExternalMappingData } from '../external/external-mapping.type';
import { DataFormatType, InternalNodeType } from '../shared/enum-mapping.type';
import { Mapper } from '../shared/mapper.type';
import { toNodeDataType } from './internal-form.type';
import { TreeNode } from './tree-node.type';
import { Validatable } from './validatable.type';

export const IS_TYPE_STATIC_ARRAY: (treeNode: TreeNode<InternalMappingData>) => boolean = (
  treeNode: TreeNode<InternalMappingData>
) => {
  return treeNode.data.type === InternalNodeType.STATIC_ARRAY;
};

export class InternalMappingData implements Validatable<InternalMappingData> {
  public type: InternalNodeType;
  public source?: string;
  public mappingSources?: MappingSourceData[];
  public format?: DataFormatType;
  public selectedMappingSourceIndex?: number;
  public mappingSourceLength?: number;

  constructor(mappingData?: ExternalMappingData, mappingSources?: MappingSourceData[]) {
    this.type = toNodeDataType(mappingData?.type);
    switch (this.type) {
      case InternalNodeType.OBJECT:
      case InternalNodeType.ARRAY:
        this._mapObjectArrayData(mappingData);
        break;
      case InternalNodeType.STATIC_ARRAY:
        this._mapStaticArrayData(mappingData);
        break;
      case InternalNodeType.FIELD:
        this._mapFieldData(mappingData, mappingSources);
        break;
    }
  }

  public isValid: () => boolean = () => {
    // TODO check
    // check static array number
    // check fields
    return true;
  };

  private _mapObjectArrayData(mappingData?: ExternalMappingData) {
    this.source = mappingData?.source;
  }

  private _mapStaticArrayData(mappingData?: ExternalMappingData) {
    this.selectedMappingSourceIndex = 0;
    this.mappingSourceLength = mappingData?.fields?.length ?? 0;
  }

  private _mapFieldData(mappingData?: ExternalMappingData, mappingSources?: MappingSourceData[]) {
    this.mappingSources = mappingSources ?? [new MappingSourceData()];
    this.format = mappingData?.format;
  }
}

export class MappingSourceData {
  mappers: Mapper[] = [];
}
