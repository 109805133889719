<div class="node-view-container">
  <app-node-header class="header center"></app-node-header>
  <mat-divider></mat-divider>
  <mat-sidenav-container class="node-body-container">
    <mat-sidenav class="side-navigation" #sidenav mode="side" [(opened)]="opened">
      <app-tree></app-tree>
    </mat-sidenav>
    <mat-sidenav-content class="node-detail">
      <button
        mat-icon-button
        class="handle-button"
        disableRipple
        class="kf-icon-button-small"
        style="cursor: pointer"
        (click)="sidenav.toggle()"
      >
        <kf-icon [name]="opened ? 'ic_chevron_links_32' : 'ic_chevron_rechts_32'"></kf-icon>
      </button>
      <app-node-detail class="main"></app-node-detail>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
