import { Injectable } from '@angular/core';
import { DefaultData } from '@type/internal/default.data';
import { InternalMappingData } from '@type/internal/internal-mapping.type';
import { TreeNode } from '@type/internal/tree-node.type';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TreeContextService {
  public readonly selectedTreeNodeSubject: BehaviorSubject<TreeNode<InternalMappingData>>;
  public readonly rootTreeNodeSubject: BehaviorSubject<TreeNode<InternalMappingData>>;

  constructor() {
    const emptyRoot = DefaultData.getEmptyDataTreeNode();
    this.rootTreeNodeSubject = new BehaviorSubject(emptyRoot);
    this.selectedTreeNodeSubject = new BehaviorSubject(emptyRoot);
  }

  public setRoot(rootTreeNode: TreeNode<InternalMappingData>) {
    this.rootTreeNodeSubject.next(rootTreeNode);
    this._setSelected(rootTreeNode);
  }

  public setSelected(selectedTreeNode?: TreeNode<InternalMappingData>) {
    this._setSelected(selectedTreeNode);
  }

  public getRoot(): TreeNode<InternalMappingData> {
    return this.rootTreeNodeSubject.getValue();
  }

  private _setSelected(selectedTreeNode?: TreeNode<InternalMappingData>) {
    if (selectedTreeNode) {
      this.selectedTreeNodeSubject.next(selectedTreeNode);
    }
  }
}
