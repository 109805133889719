<div class="flex-scroll flex-center mx-auto" style="max-width: 40rem; padding-top: 10%">
  <kf-tile [interactive]="false">
    <div class="d-flex flex-column flex-grow-1">
      <h1 class="pb-5" style="text-align: center">Export Mapper</h1>
      <mat-form-field class="notranslate kf-form-field-compact">
        <mat-label>Username</mat-label>
        <input
          matInput
          [(ngModel)]="username"
          placeholder="username"
          autocomplete="off"
          required
          (keydown.enter)="login()"
        />
      </mat-form-field>

      <mat-form-field class="notranslate kf-form-field-compact">
        <mat-label>Password</mat-label>
        <input
          matInput
          placeholder="Password"
          [(ngModel)]="password"
          [type]="hidePassword ? 'password' : 'text'"
          (keydown.enter)="login()"
          required
        />
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
          hidePassword ? 'visibility_off' : 'visibility'
        }}</mat-icon>
      </mat-form-field>

      <button mat-flat-button class="m-3" color="primary" [disabled]="!canLogin()" (click)="login()" aria-label="Login">
        <span>Login</span>
      </button>
    </div>
  </kf-tile>
</div>

<ng-template #wrongPasswordNotification>
  <div class="kf-ntofication-content" kfNotification kfNotificationStyle="Error">
    <kf-icon name="ic_warnschild_24"></kf-icon>
    <span class="kf-notification-text">Wrong username or password</span>
  </div>
</ng-template>
