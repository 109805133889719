import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositionDTO, DataFieldDTO, DataFieldGroupDTO, ProductDTO } from '@type/external/banking-guide.type';
import { environment } from 'environment/environment';
import { Observable } from 'rxjs';
import { AbstractBaseRepository } from './abstract-base.repository';

@Injectable({
  providedIn: 'root',
})
export class ConsultationAppRepository extends AbstractBaseRepository {
  private static readonly serviceNameWithSuffix = `bgzv-backend-consultation-app/api/v1`;
  constructor(protected http: HttpClient) {
    super(environment.caUrl, ConsultationAppRepository.serviceNameWithSuffix);
  }

  public getDataFieldGroups(): Observable<DataFieldGroupDTO[]> {
    return this.http.get<DataFieldGroupDTO[]>(`${this.url}/configurator/data-field-groups`, {});
  }

  public getDataFields(): Observable<DataFieldDTO[]> {
    return this.http.get<DataFieldDTO[]>(`${this.url}/configurator/data-fields`, {});
  }

  public getCompositions(): Observable<CompositionDTO[]> {
    return this.http.get<CompositionDTO[]>(`${this.url}/configurator/compositions`, {});
  }

  public getProducts(): Observable<ProductDTO[]> {
    return this.http.get<ProductDTO[]>(`${this.url}/configurator/products`, {});
  }
}
