import { Component, Input } from '@angular/core';
import { SearchValueService } from './search-value.service';

@Component({
  selector: 'app-search-value',
  templateUrl: './search-value.component.html',
  styleUrls: ['./search-value.component.scss'],
  providers: [SearchValueService],
})
export class SearchValueComponent {
  @Input() searchValue?: SearchValueService<any>;
  @Input() labelName?: string;

  constructor() {}
}
