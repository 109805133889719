import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { KFThemeService } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { Subject, takeUntil } from 'rxjs';
import { ServiceConstant } from './constants.data';
import { AbstractAuthenticationService, AuthenticationState } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public authenticated: boolean;
  private _destroy: Subject<boolean>;

  constructor(
    @Inject(ServiceConstant.authenticationService) private _authenticationService: AbstractAuthenticationService,
    private _theme: KFThemeService
  ) {
    this.authenticated = false;
    this._destroy = new Subject();

    /* ?theme=8840_1  ?theme=9998 */
    /* this.theme.applyThemeByID('8840_1');
    this.theme.applyThemeByID('9998');  */
  }

  ngOnInit(): void {
    this._authenticationService.authorizationState
      .pipe(takeUntil(this._destroy))
      .subscribe(state => this._setAuthenticated(state));
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
  }

  private _setAuthenticated(state: AuthenticationState): void {
    this.authenticated = state === AuthenticationState.LOGGED_IN;
  }
}
